.project-item {
    position: relative;
    margin: 40px 0;
    width: 100%;
    $elm: &;

    &:hover {
        #{$elm}__icon {
            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        #{$elm}__button {
            @extend .button--black:hover;
        }
    }

    &--slide {
        margin: 40px $grid-gutter-width;
    }

    &__image-wrapper {
        width: 100%;
    }

    &__image {
        width: 100%;
    }

    &__title {
        margin: 0;
    }

    &__icon {
        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__button {
        margin-top: 20px;
    }

    &__content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        padding: 25px;
        width: 100%;
        color: #ffffff;
        background: map-deep-get($colors, "brand", "primary");
        transition: all $transition-default-smoothing #{$transition-default-time * 2};
    }

    &__location {
        color: map-deep-get($colors, "black", "default");
    }

    @include media-breakpoint-up(lg) {
        &--slide {
            #{$elm}__content {
                transform: translate(40%, -50%);
            }
        }

        &__inner {
            display: flex;
            justify-content: flex-end;
        }

        &__image-wrapper {
            width: 80%;
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 0;
            width: 35%;
            height: 350px;
            transform: translate(0, -50%);
        }

        /* stylelint-disable */
        &.is-selected {
            #{$elm}__content {
                transform: translate(0, -50%);
            }
        }
        /*stylelint-enable */
    }
}