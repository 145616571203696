.person {
    margin-bottom: 40px;
    $elm: &;

    &__image-wrapper {
        position: relative;
    }

    &__image {
        width: 100%;
    }

    &__name {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 0 -0.3em;
        -webkit-font-smoothing: antialiased;
    }

    &__function {
        color: map-deep-get($colors, "brand", "primary");
        -webkit-font-smoothing: antialiased;
    }

    &--contact {
        margin-bottom: 0;

        #{$elm}__image {
            max-width: 300px;
        }

        #{$elm}__name {
            margin: 0 0 rem-calc(-26);
        }
    }
}