.sidebar {
    background: map-deep-get($colors, "white", "default");
    transition: $transition-default;
    padding: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 54px;
    $elm: &;

    @include media-breakpoint-up(md) {
        padding: 0 0 20px;
    }

    @include media-breakpoint-up(md) {
        height: 100%;
        padding-top: 20px;
        display: flex;
        flex-flow: column nowrap;
        top: 0;
        left: 0;
    }

    @include media-breakpoint-up(md) {
        padding-top: 0;
        right: auto;
        left: 0;
        width: map-get($sidebar-width, "md");
    }

    @include media-breakpoint-up(lg) {
        width: map-get($sidebar-width, "lg");
    }

    @include media-breakpoint-up(xl) {
        width: map-get($sidebar-width, "xl");
    }

    @include media-breakpoint-up(xxl) {
        width: map-get($sidebar-width, "xxl");
    }

    &__logo {
        display: block;
        width: 125px;

        @include media-breakpoint-up(md) {
            width: 68%;
            margin: 10% auto 25%;
        }

        svg {
            width: 100%;
        }
    }

    &__bottom {
        display: none;
        padding: 40px 0;
        height: 100%;
        margin-top: -20px;
        width: 100%;
        flex-wrap: wrap;
        overflow: auto;

        @include media-breakpoint-up(md) {
            display: flex;
            flex: 1;
            margin-top: 0;
            padding: 20px 0;
            overflow: visible;
        }
    }

    &__button {
        align-self: flex-end;
        margin: 0 auto;
    }

    &--expanded {
        height: 100%;

        #{$elm}__bottom {
            display: flex;
        }
    }
}