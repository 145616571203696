﻿// Colors
$colors: (
    "white": ("default": #ffffff),
    "black": ("default": #000000),
    "gray": ("light": #ebebeb, "default": #cccccc, "dark": #d1d1d1),
    "brand": ("primary": #05a4c4, "secondary": #005263),
    "validation": ("valid": #55b890, "invalid": #e50044, "valid-dark": darken(#55b890, 25%), "invalid-dark": darken(#e50044, 25%)),
    "social": ("facebook": #3b5998, "twitter": #1da1f2, "instagram": #c13584, "youtube": #ff0000, "linkedin": #0077b5),
) !default;

$body-bg: map-deep-get($colors, "black", "default");
$body-color: map-deep-get($colors, "white", "default");
$link-color: map-deep-get($colors, "brand", "primary");
$link-hover-color: map-deep-get($colors, "brand", "secondary");

//
// Fonts

$font-default: "Poppins", "Arial", sans-serif !default;
$site: "blacon" !default;

@if ($site == "blacon") {
    @import url("https://fonts.googleapis.com/css?family=Poppins:400,400i,500,500i,600,800,900");
    $colors: (
        "white": ("default": #ffffff),
        "black": ("default": #000000),
        "gray": ("light": #ebebeb, "default": #cccccc, "dark": #d1d1d1),
        "brand": ("primary": #05a4c4, "secondary": #005263),
        "validation": ("valid": #55b890, "invalid": #e50044, "valid-dark": darken(#55b890, 25%), "invalid-dark": darken(#e50044, 25%)),
        "social": ("facebook": #3b5998, "twitter": #1da1f2, "instagram": #c13584, "youtube": #ff0000, "linkedin": #0077b5),
    );
}

@if ($site == "liftcase") {
    @import url("https://fonts.googleapis.com/css?family=Ubuntu:400,400i,500,500i,700");
    $colors: (
        "white": ("default": #ffffff),
        "black": ("default": #000000),
        "gray": ("light": #ebebeb, "default": #cccccc, "dark": #d1d1d1),
        "brand": ("primary": #31a7df, "secondary": #2580ab),
        "validation": ("valid": #55b890, "invalid": #e50044, "valid-dark": darken(#55b890, 25%), "invalid-dark": darken(#e50044, 25%)),
        "social": ("facebook": #3b5998, "twitter": #1da1f2, "instagram": #c13584, "youtube": #ff0000, "linkedin": #0077b5),
    );
    $font-default: "Ubuntu", sans-serif;
}

//
// Transition defaults
$transition-default-smoothing: ease-in-out;
$transition-default-time: .3s;
$transition-default: all $transition-default-time $transition-default-smoothing;

$transition-cubic-smoothing: cubic-bezier(.2, 0, 0, 1);
$transition-cubic-time: .2s;
$transition-cubic: all $transition-cubic-time $transition-cubic-smoothing;

//
// Border Radius
$border-radius: 4px;

$enable-caret:              false;
$enable-shadows:            false;
$enable-rounded:            false;
$enable-gradients:          false;
$enable-transitions:        false;
$enable-hover-media-query:  false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true;
$enable-print-styles:       false;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$container-max-widths: (
    lg: 1200px
);

//
// Grid
$grid-columns: 24;
$grid-gutter-width: 20px;