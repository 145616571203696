.button {
    padding: 0.75em 1.25em;
    min-height: rem-calc(50);
    line-height: 1.25em;
    font-size: rem-calc(14);
    border-radius: rem-calc(50);
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;

    &:hover {
        text-decoration: none;
    }

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-left: rem-calc(-8);
        margin-right: rem-calc(8);
        vertical-align: middle;
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--large {
        @include media-breakpoint-up(md) {
            padding-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 75px;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 100px;
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: map-deep-get($colors, "brand", "secondary");
                color: map-deep-get($colors, "white", "default");
            }
        }

        &--glow {
            box-shadow: 0 14px 24px rgba(map-deep-get($colors, "brand", "primary"), 0.3);

            &:hover {
                box-shadow: 0 14px 24px rgba(map-deep-get($colors, "brand", "secondary"), 0.3);
            }
        }
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
        color: map-deep-get($colors, "white", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: map-deep-get($colors, "brand", "secondary");
                color: map-deep-get($colors, "white", "default");
            }
        }

        &--glow {
            box-shadow: 0 14px 24px rgba(map-deep-get($colors, "black", "default"), 0.3);

            &:hover {
                box-shadow: 0 14px 24px rgba(map-deep-get($colors, "brand", "secondary"), 0.3);
            }
        }
    }
}