.project {
    @include media-breakpoint-up(md) {
        &__image-wrapper {
            margin: 0 -45px;
        }
    }

    &__title {
        margin-top: 0;
    }

    &__image {
        width: 100%;
        margin: 15px auto -4.75vw;
        max-width: 1200px;
        display: flex;

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(-32);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: rem-calc(-37);
        }
    }

    &__specs {
        padding: 40px 40px 20px;
        color: map-deep-get($colors, "white", "default");
        background: map-deep-get($colors, "brand", "primary");
    }

    &__specs-title {
        margin: 0 0 20px;
        word-break: break-all;
    }
}