.project-slider {
    &__prev,
    &__next {
        display: inline-block;
        padding: 10px 0;
        margin-right: 20px;

        svg {
            width: 24px;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__current {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary");
    }

    &__total {
        font-weight: 700;
    }
}