.main {
    max-width: 100%;
    height: 100%;
    position: relative;
    transition: $transition-default;
    flex: 1 1 100%;

    &__inner {
        height: 100%;
        padding-top: 50px;

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    @include media-breakpoint-up(md) {
        padding-left: map-get($sidebar-width, "md");
    }

    @include media-breakpoint-up(lg) {
        padding-left: map-get($sidebar-width, "lg");
    }

    @include media-breakpoint-up(xl) {
        padding-left: map-get($sidebar-width, "xl");
    }

    @include media-breakpoint-up(xxl) {
        padding-left: map-get($sidebar-width, "xxl");
    }
}