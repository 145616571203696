.sidebar-nav {
    position: relative;
    flex: 1 1 100%;

    $elm: &;

    // ul tag

    &__list {
        margin-bottom: 1em;

        &--sub {
            display: none;
        }
    }

    // actief streepje, staat als losse span zodat deze kan animeren

    &__indicator {
        left: 0;
        top: 0;
        height: rem-calc(40);
        width: rem-calc(4);
        background: map-deep-get($colors, "brand", "primary");
        display: inline-block;
        position: absolute;

        @include media-breakpoint-up(lg) {
            height: rem-calc(50);
        }

        @at-root body.body--loaded & {
            transition: $transition-default;
        }
    }

    &--sub-active {
        // streepje weglaten

        #{$elm}__indicator {
            display: none;
        }

        // sublist tonen

        #{$elm}__list {
            display: block;
            width: 100%;
        }

        // alle list-items verbergen

        #{$elm}__item {
            display: none;
        }

        // actief list-item tonen

        #{$elm}__item--hover {
            display: flex;
            flex-wrap: wrap;

            // actief list item link verbergen

            #{$elm}__link {
                display: none;
            }

            // actief list item items tonen

            #{$elm}__item {
                display: flex;

                // actief list item items link tonen
                // stylelint omdat het gewoon even moet

                /* stylelint-disable */
                #{$elm}__link {
                    display: block;
                    background: none;
                }
                /* stylelint-enable */
            }
        }
    }

    // title

    &__title {
        color: map-deep-get($colors, "gray", "default");
        margin-bottom: 1em;
        margin-top: 2em;
    }

    // list item

    &__item {
        align-items: stretch;
        display: flex;
        min-height: rem-calc(40);

        &--back {
            margin-bottom: 0.75em;
        }
    }

    // a tag

    &__link {
        align-items: center;
        color: map-deep-get($colors, "black", "default");
        display: flex;
        font-size: rem-calc(12);
        font-weight: 800;
        margin-bottom: 0.5em;
        letter-spacing: 0.2em;
        line-height: 1.5em;
        padding: 0.9em 0 0.9em 20px;
        text-transform: uppercase;
        width: 100%;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(14);
            margin-bottom: 1em;
            line-height: 1.8em;
        }

        &--back {
            color: map-deep-get($colors, "brand", "primary");
            cursor: pointer;
        }
    }

    &__back-icon {
        display: inline-block;
        width: 2em;
        height: 1.5em;
        margin-right: .875em;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    // inline svg

    &__icon {
        display: inline-flex;
        line-height: 1em;
        height: 2em;
        margin-right: .5em;
        width: 2em;
        vertical-align: middle;

        svg {
            fill: map-deep-get($colors, "black", "default");
            height: 100%;
            max-height: rem-calc(20);
            max-width: rem-calc(16);
            width: 100%;
        }
    }

    // actief item

    &__link:not(#{$elm}__item--active):hover {
        #{$elm}__link {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &__item--active {
        #{$elm}__link {
            background: map-deep-get($colors, "gray", "light");
        }
    }
}