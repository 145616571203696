.video {
    /* stylelint-disable */
    height: 100vh;
    height: calc(100vh - 50px);
    height: calc((var(--vh, 1vh) * 100) - 50px);
    /* stylelint-enable */
    margin-bottom: 50px;
    position: relative;
    transition: $transition-default;

    $elm: &;

    @include media-breakpoint-up(md) {
        height: 100vh;
        // backup
        /* stylelint-disable */
        height: calc(var(--vh, 1vh) * 100);
        /* stylelint-enable */
        margin-bottom: 60px;
        margin-left: -50px;
        margin-right: -50px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 75px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 100px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 590px;
    }

    &__inner {
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 0;
        position: relative;
        z-index: 10;

        @include media-breakpoint-up(md) {
            padding: 50px 0;
        }
    }

    &__top {
        flex: 0 0 100%;
    }

    &__content {
        flex: 1 1 100%;
    }

    &__button-wrapper {
        align-self: flex-end;
        flex: 0 0 100%;
    }

    &__button {
        cursor: pointer;

        /* stylelint-disable */
        svg {
            .st0, .st1 {
                transition: $transition-default;
            }
        }
        /* stylelint-enable */

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                text-decoration: none;

                /* stylelint-disable */
                #{$elm}__button-icon svg {
                    .st0 {
                        fill: map-deep-get($colors, "brand", "primary");
                    }

                    .st1 {
                        fill: map-deep-get($colors, "black", "default");
                    }
                }
                /* stylelint-enable */
            }
        }
    }

    &__button-icon {
        margin-right: 1em;
    }

    &__button-text {
        text-decoration: underline;
        color: map-deep-get($colors, "white", "default");
        text-transform: uppercase;
        font-weight: 600;
    }

    &__wrapper {
        background: map-deep-get($colors, "gray", "darker");
        opacity: 0.5;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__bg {
        background-color: rgba(map-deep-get($colors, "gray", "default"), 0.1);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
    }

    /* stylelint-disable */
    .video-js .vjs-tech {
        width: auto;
        min-width: 100%;
        height: auto;
        bottom: 0;
        min-height: 100%;
    }
    /* stylelint-enable */
}

/* stylelint-disable */
.background-video {
    transition: $transition-default;
    //opacity: 0;
    overflow: hidden;
    z-index: 0;

    &.vjs-playing {
        opacity: 1;
    }

    .video-js {
        background-color: transparent;
        height: 100%;
        width: 100%;

        &, .vjs-tech {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: auto;
            min-width: 100%;
        }
    }

    &, .vjs-poster {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    video {
        &, .vjs-tech {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.footer-video video,
    &.footer-video .vjs-tech {
        object-position: bottom;
    }
}
/* stylelint-enable */