.subservice {
    width: 100%;
    padding-bottom: 50px;
    $elm: &;

    &__inner {
        position: relative;

        &::before {
            transition: $transition-default;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }

    &__image {
        width: 100%;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        position: absolute;
        margin: 0;
        bottom: -.75em;
        left: 0;
        right: 0;
        z-index: 2;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(45);
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(54);
        }
    }

    &:hover {
        #{$elm}__inner::before {
            background: rgba(map-deep-get($colors, "brand", "primary"), .6);
        }
    }
}