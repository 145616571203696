.service {
    @include media-breakpoint-up(md) {
        &__image-wrapper {
            margin: 0 -45px;
        }
    }

    &__title {
        margin-top: 0;
    }

    &__image {
        width: 100%;
        margin: 15px auto -4.75vw;
        max-width: 1180px;
        display: flex;

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(-32);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: rem-calc(-37);
        }
    }
}