.text-block {
    position: relative;
    width: 100%;
    min-height: 40vw;
    display: flex;
    margin-bottom: 50px;
    $elm: &;

    @include media-breakpoint-up(md) {
        min-height: 400px;
        margin-bottom: 60px;
    }

    @include media-breakpoint-up(lg) {
        height: 10px;
        min-height: 500px;
        margin-bottom: 75px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 560px;
        margin-bottom: 100px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 590px;
    }

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-size: cover;
        background-position: center center;
        background-color: map-deep-get($colors, "gray", "default");

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(map-deep-get($colors, "brand", "primary"), 0.8);
            transition: $transition-default;
        }
    }

    &__link {
        width: 100%;

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                text-decoration: none;

                #{$elm}__button {
                    @extend .button--black:hover;
                }
            }
        }
    }

    &__inner {
        padding: 20px 15px;
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1 1 100%;
        flex-flow: column wrap;

        @include media-breakpoint-up(md) {
            padding: 30px 22px;
        }

        @include media-breakpoint-up(md) {
            padding: 60px 45px;
        }
    }

    &__title {
        margin: 0 0 1em;
        color: map-deep-get($colors, "white", "default");
    }

    &__subtitle {
        margin: 0;
        color: map-deep-get($colors, "black", "default");
    }

    &__content {
        color: map-deep-get($colors, "white", "default");
        line-height: 1.8em;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
        }
    }

    &__button {
        color: map-deep-get($colors, "white", "default");
    }

    @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
        &:hover {
            #{$elm}__bg::before {
                top: 5px;
                right: 5px;
                bottom: 5px;
                left: 5px;

                @include media-breakpoint-up(md) {
                    top: 20px;
                    right: 20px;
                    bottom: 20px;
                    left: 20px;
                }
            }
        }
    }
}