.scroll-down {
    pointer-events: all;
    align-items: center;
    bottom: 0;
    display: none;
    position: absolute;
    right: 20px;
    text-transform: uppercase;
    transform: rotate(90deg);
    transform-origin: top right;
    text-decoration: underline;
    white-space: nowrap;

    /* stylelint-disable */
    transform-origin: calc(100% - 20px) center;
    /* stylelint-enable */

    @include media-breakpoint-up(xl) {
        display: flex;
    }

    $elm: &;

    &__icon {
        align-items: center;
        background: map-deep-get($colors, "white", "default");
        display: inline-flex;
        height: 3.5em;
        justify-content: center;
        margin-left: 1em;
        transition: $transition-default;
        width: 4.3em;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
            height: 1em;
            transition: $transition-default;
            width: 1.5em;
        }
    }

    &:hover {
        color: map-deep-get($colors, "brand", "primary");

        #{$elm}__icon {
            background: map-deep-get($colors, "brand", "primary");

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }
}

.scroll-down-wrapper {
    pointer-events: none;
    height: 100vh;
    /* stylelint-disable */
    height: calc(var(--vh, 1vh) * 100);
    /* stylelint-enable */
    right: 0;
    position: absolute;
    top: 0;
    z-index: 50;
    width: 100%;
}