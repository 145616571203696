.navigation {
    $elm: &;
    padding: 20px 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 0;
        margin-left: 10px;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
        width: auto;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 30px;
    }

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 0;
        overflow: hidden;
        display: flex;

        @include media-breakpoint-up(md) {
            height: auto;
            overflow: visible;
        }

        &--active {
            transition: all ease-in-out 0s 0s;
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        opacity: 0;
        display: block;
        position: relative;
        padding: 10px 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0 8px;
            opacity: 1;
            display: inline-block;
        }

        @include media-breakpoint-up(md) {
            &--has-sub:hover {
                #{$elm}__item {
                    background: map-deep-get($colors, "white", "default");
                    color: map-deep-get($colors, "black", "default");
                    margin-top: 10px;
                    padding-bottom: 15px;
                }

                #{$elm}__list--sub {
                    display: block;

                    #{$elm}__item {
                        padding: 5px 0;
                        margin: 0;
                    }
                }
            }
        }
    }

    &__item {
        display: inline-block;
        font-size: rem-calc(16);
        font-weight: 900;
        line-height: 1.25em;
        color: map-deep-get($colors, "black", "default");
        padding: 5px;
        white-space: nowrap;
        transition: $transition-default;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        &:hover {
            text-decoration: underline;
        }

        &--active {
            text-decoration: underline;
        }
    }

    &__list--sub {
        display: block;
        padding: 8px;

        #{$elm}__list-item {
            display: block;
            width: 100%;
        }

        #{$elm}__item {
            font-weight: 600;
            line-height: 1.5em;
            font-size: rem-calc(16);
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
            background: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "black", "default");
        }
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            padding: 5px 0;
        }
    }
}