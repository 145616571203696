.pagination {
    display: flex;
    justify-content: flex-end;

    &__list {
        margin: 0;
    }

    &__list-item {
        display: inline-block;
        padding-left: 15px;
    }

    &__control-link,
    &__list-link {
        display: inline-block;
        padding: 10px 16.5px;
        min-width: 50px;
        font-weight: 700;
        text-align: center;
        color: map-deep-get($colors, "black", "default");
        border-radius: 25px;
        border: 2px solid map-deep-get($colors, "white", "default");
        background: map-deep-get($colors, "white", "default");
        transition: $transition-default;

        &:hover {
            text-decoration: none;
            color: map-deep-get($colors, "white", "default");
            border: 2px solid map-deep-get($colors, "brand", "primary");
            background: map-deep-get($colors, "brand", "primary");
        }

        &--active {
            color: map-deep-get($colors, "white", "default");
            border: 2px solid map-deep-get($colors, "brand", "primary");
            background: map-deep-get($colors, "black", "default");
        }

        &--control {
            text-transform: uppercase;
            color: map-deep-get($colors, "white", "default");
            border: 2px solid map-deep-get($colors, "brand", "primary");
            background: map-deep-get($colors, "brand", "primary");

            &:hover {
                border: 2px solid darken(map-deep-get($colors, "brand", "primary"), 20);
                background: darken(map-deep-get($colors, "brand", "primary"), 20);
            }
        }
    }

    &__control-link {
        &--inactive {
            display: none;
        }
    }
}