﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

p {
    margin-top: 0;
}

h1, .h1 {
    font-weight: 900;
    font-size: 9.5vw;
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: .75em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(50);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(74);
    }
}

h2, .h2 {
    font-size: 8vw;
    font-weight: 900;
    line-height: 1.314em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(45);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(54);
    }
}

h3, .h3 {
    font-size: rem-calc(30);
    font-weight: 900;
    line-height: 1.318em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(40);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(44);
    }
}

h4, .h4 {
    font-size: rem-calc(24);
    font-weight: 900;
    line-height: 1.323em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(30);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(34);
    }
}

h5, .h5 {
    font-size: rem-calc(16);
    font-weight: 900;
    line-height: 1.312em;
    margin-bottom: 0.5em;
    letter-spacing: 0.4em;
    margin-top: 1.5em;
    text-transform: uppercase;
}

h6, .h6 {
    font-size: rem-calc(14);
    font-weight: 600;
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
    text-transform: uppercase;
}

strong {
    font-weight: 900;
}