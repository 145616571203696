.contact-block {
    background: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");
    padding: 20px;
    width: 100%;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
        padding: 24px 25px 15px;
    }

    @include media-breakpoint-up(lg) {
        padding: 40px 30px 20px;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 1em;
    }

    &__row {
        margin: 1em 0 1.5em;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__value {
        min-height: 2.25em;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: rem-calc(14);
        letter-spacing: 0.2em;
        font-weight: 800;
    }

    &__icon {
        display: inline-block;
        height: 2em;
        margin-right: 1.5em;
        vertical-align: middle;
        width: 2em;

        @include media-breakpoint-up(lg) {
            height: 2.25em;
            width: 2.25em;
        }

        @include media-breakpoint-up(xl) {
            height: 2.5em;
            width: 2.5em;
        }

        svg {
            height: 100%;
            width: 100%;
            fill: map-deep-get($colors, "black", "default");
        }

        &--route {
            svg {
                height: 120%;
                width: 120%;
                margin: -10%;
            }
        }

        &--social {
            svg {
                width: 70%;
                height: 70%;
                margin: auto;
                display: flex;
            }
        }
    }

    &__link {
        text-decoration: underline;
        color: map-deep-get($colors, "white", "primary");
    }

    &__kvk {
        font-weight: 800;
        margin-top: 0.8em;
        color: map-deep-get($colors, "white", "default");
        -webkit-font-smoothing: antialiased;
        opacity: 0.5;
        letter-spacing: 0.2em;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(16);
        }
    }
}