input, select {
    background: transparent;
    color: map-deep-get($colors, "white", "default");
    height: rem-calc(50);
    border: 1px solid map-deep-get($colors, "brand", "primary");
    padding: 0 rem-calc(16);
    width: 100%;
    transition: $transition-default;
    font-family: $font-default;

    &:focus {
        outline: none;
        border: 3px solid map-deep-get($colors, "brand", "primary");
    }
}

textarea {
    background: transparent;
    color: map-deep-get($colors, "white", "default");
    border: 1px solid map-deep-get($colors, "brand", "primary");
    padding: 8px 16px;
    resize: vertical;
    min-height: rem-calc(150);
    width: 100%;
    transition: border .3s ease-in-out;
    font-family: $font-default;

    &:focus {
        outline: none;
        border: 3px solid map-deep-get($colors, "brand", "primary");
    }
}

select {
    appearance: none;
    background: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "white", "default");

    &::-ms-expand {
        display: none;
    }
}

.select-wrapper {
    position: relative;

    &__select {
        height: rem-calc(50);
        color: map-deep-get($colors, "white", "default");
        border: 1px solid map-deep-get($colors, "gray", "default");
        font-weight: 500;
        padding: 0 20px;

        &:focus {
            border: 1px solid map-deep-get($colors, "gray", "default");
        }
    }

    &__dropdown-icon {
        position: absolute;
        right: rem-calc(10);
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
    }

    //Directly referencing option element because of select context.
    /* stylelint-disable */
    option {
        font-size: 18px;
        padding: 12px 0;
    }
    /* stylelint-enable */
}

//Radio Button styling

.radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(8);
    $radioWrapper: &;

    &__input-wrapper {
        position: relative;
        width: rem-calc(25);
        height: rem-calc(25);
        margin-right: rem-calc(8);
        float: left;
        cursor: pointer;
        flex-shrink: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid map-deep-get($colors, "brand", "primary");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: rem-calc(5);
        top: rem-calc(5);
        z-index: 2;
        border-radius: 50%;
        background-color: map-deep-get($colors, "brand", "primary");
        width: rem-calc(15);
        height: rem-calc(15);
        opacity: 0;
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            + #{$radioWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        font-size: rem-calc(15);
        cursor: pointer;
        font-weight: 400;
    }
    /* stylelint-enable */
}

.checkbox-wrapper {
    display: flex;
    align-items: start;
    margin-bottom: rem-calc(8);
    $checkboxWrapper: &;

    &__input-wrapper {
        position: relative;
        cursor: pointer;
        flex-shrink: 0;
        line-height: rem-calc(28);
    }

    &__input-dot {
        width: rem-calc(30);
        height: rem-calc(30);
        background-color: transparent;
        border: 3px solid map-deep-get($colors, "brand", "primary");
        margin-right: rem-calc(20);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            opacity: 0;
            fill: map-deep-get($colors, "brand", "primary");
            width: 70%;
            height: 70%;
        }
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            + #{$checkboxWrapper}__input-dot {
                svg {
                    opacity: 1;
                }
            }
        }
    }

    &__label, label {
        cursor: pointer;
        align-self: center;
        font-weight: 400;
        text-transform: none;
    }
    /* stylelint-enable */
}

.form {
    &__button {
        @extend .button--primary;
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);

        &:hover {
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
        }
    }
}