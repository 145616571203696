.footer {
    color: map-deep-get($colors, "white", "default");
    position: relative;
    z-index: 15;
    padding-top: 50px;
    flex: 1 1 100%;

    @include media-breakpoint-up(md) {
        padding-left: map-get($sidebar-width, "md");
    }

    @include media-breakpoint-up(lg) {
        padding-left: map-get($sidebar-width, "lg");
        padding-top: 120px;
    }

    @include media-breakpoint-up(xl) {
        padding-left: map-get($sidebar-width, "xl");
    }

    @include media-breakpoint-up(xxl) {
        padding-left: map-get($sidebar-width, "xxl");
    }

    a:not(.button):not(:hover) {
        color: map-deep-get($colors, "white", "default");
    }

    &__video-wrapper {
        width: 100%;
        position: relative;
        height: 0;
        padding-top: 77%;
        margin-top: -28vw;
        margin-bottom: -10%;
        z-index: -1;
        background-image: url(#{"../images/template/"+$site+"-footer.jpg"});
        background-size: cover;
        background-position: center bottom;

        &::before {
            content: "";
            background: linear-gradient(0deg, map-deep-get($colors, "black", "default") 0, rgba(map-deep-get($colors, "black", "default"), 0) 55%, rgba(map-deep-get($colors, "black", "default"), 1) 100%);
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1%;
            top: -1%;
            z-index: 1;
        }
    }

    &__video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // dynamische content
    /* stylelint-disable */
    .form {
        padding-bottom: 50px;
    }
    /* stylelint-enable */

    &__bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &__logo {
        width: 80%;
        max-width: 420px;
        display: flex;
        margin: 0 auto 20px;

        @include media-breakpoint-up(md) {
            margin: 0 auto 50px;
        }

        svg {
            width: 100%;
            height: 100%;

            // SVG content
            /* stylelint-disable */
            .logo-small__text {
                fill: map-deep-get($colors, "white", "default");
            }
            /* stylelint-enable */
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 100;
        color: map-deep-get($colors, "white", "default");
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-block;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
            align-items: center;

            &::after {
                content: "";
                width: 0.25em;
                height: 0.25em;
                background: map-deep-get($colors, "white", "default");
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &__item {
        color: inherit;
        text-decoration: underline;

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &__webnl {
        transition: $transition-default;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}